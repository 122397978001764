import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import axios from './axios';
import moment from 'moment-timezone';

const initialState = {
    todayRevenue: 0,
    totalRevenue: 0,
    totalAccount: 0,
    totalSite: 0,
    loading: false,
    error: null,
};

export const fetchStatistics = createAsyncThunk(
    'statistics/fetchStatistics',
    async ({ startDate, endDate }, { rejectWithValue }) => {
        try {
            // Chuyển endDate thành cuối ngày
            const endOfDay = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');

            // Sử dụng startDate và endOfDay cho truy vấn API
            const response = await axios.get(`/api/v1/statistics-data/?start_date=${startDate}&end_date=${endOfDay}`);

            const { todayRevenue, totalRevenue, totalAccount, totalSite } = response;

            return {
                todayRevenue,
                totalRevenue,
                totalAccount,
                totalSite
            };
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


export const increaseTotalAccount = createAction('statistics/increaseTotalAccount');
export const updateRevenueFromTransaction = createAction('statistics/updateRevenueFromTransaction', (coins) => ({
    payload: { coins },
}));

const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStatistics.pending, (state) => {
                state.loading = true;
            })
            .addCase(increaseTotalAccount, (state) => {
                state.totalAccount += 1;
            })

            .addCase(updateRevenueFromTransaction, (state, action) => {
                // Assuming action.payload contains the coins value as a string
                const { coins } = action.payload;
                // Convert coins to a float value to include decimals
                const coinsValue = parseFloat(coins);
                if (!isNaN(coinsValue)) { // Check if coinsValue is a valid number
                    const oldTodayRevenue = state.todayRevenue;
                    state.totalRevenue = parseFloat((state.totalRevenue + coinsValue).toFixed(2));

                    state.todayRevenue = parseFloat((state.todayRevenue + coinsValue).toFixed(2));

              
                }
            })

            .addCase(fetchStatistics.fulfilled, (state, action) => {
                state.todayRevenue = action.payload.todayRevenue;
                state.totalRevenue = action.payload.totalRevenue;
                state.totalAccount = action.payload.totalAccount;
                state.totalSite = action.payload.totalSite;
                state.loading = false;
            })
            .addCase(fetchStatistics.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export default statisticsSlice.reducer;
