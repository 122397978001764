import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCryptosData } from '../../../services/cryptoSlice';
import axios from 'axios';
import { GridLoader } from 'react-spinners';

function CryptosTable() {
    const dispatch = useDispatch();
    const { cryptosData, loading, error } = useSelector(state => state.cryptos);
    const [cryptosState, setCryptosState] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newCrypto, setNewCrypto] = useState({ cryptos_name: '', cryptos_quantity: '', cryptos_buymarket: '', cryptos_usdbuy: '', cryptos_usdsell: '' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await dispatch(fetchCryptosData()).unwrap();
                const updatedData = await fetchCurrentPrices(data);
                setCryptosState(updatedData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 300000);
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        const updatePrices = async () => {
            const updatedData = await fetchCurrentPrices(cryptosData);
            setCryptosState(updatedData);
        };
        updatePrices();
    }, [cryptosData]);

    const fetchCurrentPrices = async (cryptos) => {
        try {
            const updatedCryptos = await Promise.all(
                cryptos.map(async (crypto) => {
                    try {
                        const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=${crypto.cryptos_name.toUpperCase()}USDT`);
                        const currentPrice = response.data.price ? parseFloat(response.data.price) : 0;

                        return {
                            ...crypto,
                            current_price: currentPrice
                        };
                    } catch (error) {
                        console.error(`Error fetching price for ${crypto.cryptos_name}:`, error);
                        return {
                            ...crypto,
                            current_price: 0
                        };
                    }
                })
            );

            return updatedCryptos;
        } catch (error) {
            console.error('Error fetching current prices:', error);
            return cryptos;
        }
    };


    const handleInputChange = (cryptoId, field, value) => {
        setCryptosState(prevState =>
            prevState.map(crypto =>
                crypto.id === cryptoId ? { ...crypto, [field]: value } : crypto
            )
        );
    };

    const deleteCryptoData = async (cryptoId) => {
        try {
            const response = await axios.delete(`/api/cryptos/${cryptoId}/`);
            if (response.status === 204) {
                setCryptosState(prevState => prevState.filter(crypto => crypto.id !== cryptoId));
            }
        } catch (error) {
            console.error('Failed to delete crypto:', error);
        }
    };

    const updateCryptoData = async (crypto) => {
        try {
            const response = await axios.put(`/api/cryptos/${crypto.id}/`, crypto);
            if (response.status === 200) {
                // Handle successful update logic here
            }
        } catch (error) {
            console.error('Failed to update crypto data:', error);
        }
    };

    const handleSaveNewCrypto = async () => {
        try {
            const response = await axios.post('/api/cryptos/', newCrypto);
            if (response && response.data) {
                setCryptosState(prev => [...prev, response.data]);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error('Failed to add crypto:', error);
        }
    };

    const calculateTotals = () => {
        let totalInvestment = 0;
        let totalCurrent = 0;

        cryptosState.forEach(crypto => {
            totalInvestment += parseFloat(crypto.cryptos_buymarket);
            totalCurrent += (parseFloat(crypto.current_price) * parseFloat(crypto.cryptos_quantity));
        });

        const rate = totalCurrent - totalInvestment;
        const percent = totalInvestment ? ((rate / totalInvestment) * 100).toFixed(2) : 0;

        return {
            totalInvestment: totalInvestment.toFixed(2),
            totalCurrent: totalCurrent.toFixed(2),
            rate: rate.toFixed(2),
            percent: percent
        };
    };

    const totals = calculateTotals();


    if (loading) {
        return (
            <div className='w-full flex justify-center items-center'>
                <div className='rounded-sm justify-center items-center'>
                    <GridLoader color={"#123abc"} />
                </div>
            </div>
        );
    }

    if (error) {
        return <p className="text-red-500 text-center">Error loading data: {error}</p>;
    }

    return (
        <div className="mt-5 ">
            <div class="relative mb-5">
                <table class="text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                    <thead class="text-xs text-white uppercase bg-blue-500 dark:text-white">
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                Total Investment
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Total Current
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Rate
                            </th>
                            <th scope="col" class="px-6 py-3">
                                Percent
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-gray-50 ">
                            <th scope="row" class="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-black border border-gray-300 font-semibold">
                            ${totals.totalInvestment}
                            </th>
                            <td class="px-6 py-4 text-black border border-gray-300 font-semibold">
                            ${totals.totalCurrent}
                            </td>
                            <td class="px-6 py-4 text-black border border-gray-300 font-semibold" >
                            ${totals.rate}
                            </td>
                            <td className={`px-6 py-4 border border-gray-300 font-semibold ${totals.percent < 0 ? 'text-red-500' : totals.percent > 0 ? 'text-green-500' : 'text-gray-500'}`}>
    {totals.percent}%
</td>

                        </tr>



                    </tbody>
                </table>
            </div>

            <div className="container">
                <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr className=''>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Name</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Quantity</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Buy Market</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Current Wallet</th>
                            <th className="border border-gray-300 p-1 px-6 py-4text-xs">USD Buy</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">USD Sell</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Averaging Price</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Current Price</th>
                            
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Rate</th>
                            <th className="border border-gray-300 p-1 px-6 py-4 text-xs">Percent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cryptosState.map(crypto => {
                            const averagingPrice = crypto.cryptos_quantity ? (crypto.cryptos_buymarket / crypto.cryptos_quantity).toFixed(2) : 0;
                            const rate = ((crypto.current_price * crypto.cryptos_quantity) - crypto.cryptos_buymarket).toFixed(2);
                            const rateStyle = rate < 0 ? 'bg-red-400' : 'bg-green-400';
                            const percent = crypto.cryptos_buymarket ? (((crypto.current_price * crypto.cryptos_quantity) - crypto.cryptos_buymarket) / crypto.cryptos_buymarket * 100).toFixed(2) : 0;
                            const percentStyle = percent < 0 ? 'bg-red-400' : 'bg-green-400';
                            const current_wallet = (crypto.current_price * crypto.cryptos_quantity).toFixed(2)
                            return (
                                <tr key={crypto.id} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 text-xs">
                                    <th scope="row" className="border border-gray-300 p-1 px-6 py-4">
                                        {crypto.cryptos_name}
                                    </th>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        {crypto.cryptos_quantity}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${crypto.cryptos_buymarket}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${current_wallet}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${crypto.cryptos_usdbuy}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${crypto.cryptos_usdsell}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${averagingPrice}
                                    </td>
                                    <td className="border border-gray-300 p-1 px-6 py-4 text-xs">
                                        ${crypto.current_price || 0}
                                    </td>
                                   
                                    <td className={`border border-gray-300 p-1 px-6 py-4 text-xs font-semibold ${rateStyle}`}>
                                        ${rate}
                                    </td>
                                    <td className={`border border-gray-300 p-1 px-6 py-4 text-xs font-semibold ${percentStyle}`}>
                                        {percent}%
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {isModalOpen && (
                <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                        <div className="fixed inset-0 flex items-center justify-center z-10">
                            <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4 md:max-w-md w-full">
                                <div className="flex justify-center">
                                    <div className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8">
                                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add Crypto</h3>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                value={newCrypto.cryptos_name}
                                                onChange={(e) => setNewCrypto({ ...newCrypto, cryptos_name: e.target.value })}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Quantity
                                            </label>
                                            <input
                                                type="text"
                                                value={newCrypto.cryptos_quantity}
                                                onChange={(e) => setNewCrypto({ ...newCrypto, cryptos_quantity: e.target.value })}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                Buy Market
                                            </label>
                                            <input
                                                type="text"
                                                value={newCrypto.cryptos_buymarket}
                                                onChange={(e) => setNewCrypto({ ...newCrypto, cryptos_buymarket: e.target.value })}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                USD Buy
                                            </label>
                                            <input
                                                type="text"
                                                value={newCrypto.cryptos_usdbuy}
                                                onChange={(e) => setNewCrypto({ ...newCrypto, cryptos_usdbuy: e.target.value })}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                                USD Sell
                                            </label>
                                            <input
                                                type="text"
                                                value={newCrypto.cryptos_usdsell}
                                                onChange={(e) => setNewCrypto({ ...newCrypto, cryptos_usdsell: e.target.value })}
                                                className="p-1 border border-gray-300 rounded"
                                            />
                                        </div>
                                        <button
                                            className="bg-blue-500 hover:bg-blue-700 text-white text-sm font-bold py-2 px-4 rounded"
                                            onClick={handleSaveNewCrypto}
                                        >
                                            Save
                                        </button>
                                        <button
                                            className="bg-gray-300 text-gray-700 text-sm font-bold py-2 px-4 rounded ml-2"
                                            onClick={() => setIsModalOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CryptosTable;
