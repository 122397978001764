import '../App.css';
import Header from "../components/common/header";
import Footer from '../components/common/footer';
import OverView from '../components/generals/overview'
import Proxy from '../components/generals/proxy';
import HistoryTransactions from '../components/apps/transactions-history';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import LoginForm from "../components/common/loginform";
import SideBar from '../components/common/sidebar';
import TimeLine from '../components/common/timeline';
import MembersManagerment from '../components/apps/members-management';
import UsersAnalytics from '../components/statistical/usersanalytics';
import SitesAnalytics from '../components/statistical/sitesanalytics';
import Managerment from '../components/managerment/managerment';
import EmployeeManagerment from '../components/managerment/employee';
import ClickerSetting from '../components/clicker/clicker';
import Whitelist from '../components/managerment/whitelist';
import ChatBox from '../components/common/chatbox';
import Crypto from '../components/cryto/crypto';




function Dashboard({ currentPage, loadingBarRef }) {
   

    const [isSidebarVisible, setIsSidebarVisible] = useState(window.innerWidth > 768);
    useEffect(() => {
        const handleResize = () => {
            // Cập nhật trạng thái dựa trên độ rộng màn hình
            setIsSidebarVisible(window.innerWidth > 768);
        };

        // Đăng ký event listener
        window.addEventListener('resize', handleResize);

        // Dọn dẹp khi component unmounted
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };
    const navigate = useNavigate();
    useEffect(() => {
        // Hiển thị thanh tiến trình
        loadingBarRef.current.continuousStart();
        loadingBarRef.current.complete();
        // Kiểm tra trạng thái đăng nhập
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

            if (isTokenExpired) {
                // Token hết hạn, chuyển hướng đến trang đăng nhập
                navigate('/login');
            }
        } else {
            // Không có accessToken, chuyển hướng đến trang chủ
            navigate('/login');
        }
    }, [navigate, currentPage, loadingBarRef]);

    if (currentPage === 'login') {
        return (

            <div className="dashboard">
                <LoginForm />
            </div>
        );
    }
    console.log(currentPage)
    return (
        <div className="dashboard">
            <div className={`app flex-grow`}>

                <SideBar isVisible={isSidebarVisible} />


                <div className={`app-container flex-grow ${isSidebarVisible ? 'appopen' : 'ml-0'}`}>
                    <div className='container-fluid'>
                        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarVisible} />
                        {currentPage === 'overview' && <OverView />}
                        {currentPage === 'proxy' && <Proxy />}
                        {/* Thêm các điều kiện khác cho các trang khác dựa vào currentPage */}
                        {currentPage === 'transactions-history' && <HistoryTransactions />}
                        {currentPage === 'members-management' && <MembersManagerment />}
                        {currentPage === 'user-analytics' && <UsersAnalytics />}
                        {currentPage === 'sites-analytics' && <SitesAnalytics />}
                        {currentPage === 'sites-management' && <Managerment />}
                        {currentPage === 'employee' && <EmployeeManagerment />}
                        {currentPage === 'clickersetting' && <ClickerSetting />}
                        {currentPage === 'whitelist' && <Whitelist />}
                        {currentPage === 'crypto' && <Crypto />}
                      
                        
                    </div>
                    <TimeLine />
                    <ChatBox />
                    <Footer />
                </div>
                
            </div>
        </div>
    );
}

export default Dashboard;