import axios from "../services/axios";

const fetchAuth = (username, password, ip) => {
    return axios.post("/api/token/", {
        username,
        password,
        ip
    });
}

export { fetchAuth };


