import NetworkTrendChart from "./overview/networktrendchart";
import SitesTableData from "./overview/sitestabledata";
import SitesTrendChart from "./overview/sitestrendchart";
import Statistics from "./overview/statistics ";
import TopOfferTableData from "./overview/topoffertabledata";
import WorkTrendChart from "./overview/worktrendchart";
import DailyRankChart from "./overview/dailyrankchart";

function OverView() {
    return (
        <div className="mt-12 mt-20">
            <div className="statistics">
                <div className="pl-5">
                    <div className="font-semibold">
                        Statitics
                    </div>
                    <div className="text-xs">
                        Tóm tắt đánh giá kết quả chung của công việc
                    </div>
                </div>
                <div className="mb-5">
                    <Statistics />
                </div>
            </div>
            <div className="statistics">
                <div className="pl-5">
                    <div className="font-semibold">
                        Daily Ranking
                    </div>
                    <div className="text-xs">
                        Kết quả từng nhân viên trong ngày
                    </div>
                </div>
                <div className="mb-5">
                    <DailyRankChart />
                </div>
            </div>

            <div className="charttrend">
                <div className="pl-5">
                    <div className="font-semibold">
                        Analytics
                    </div>
                    <div className="text-xs">
                        Thống kê kết quả chung của công việc
                    </div>
                </div>
                <div className="charttrend mb-6 grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">
                    <WorkTrendChart />
                    <SitesTrendChart />
                </div>
            </div>
            <div className="charttrend">
                <div className="pl-5">
                    <div className="font-semibold">
                        Performance
                    </div>
                    <div className="text-xs">
                        Tóm tắt hiệu suất của công việc
                    </div>
                </div>
                <div className="charttrend mb-6 grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">
                    <NetworkTrendChart />
                    <TopOfferTableData />
                </div>
            </div>
            <div className="site-analytics">
                <div className="pl-5">
                    <div className="font-semibold">
                        Site Performance
                    </div>
                    <div className="text-xs">
                        Tóm tắt hiệu suất của từng site
                    </div>
                </div>
                <div className="sitesdata mb-6 grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-5 p-1">
                    <SitesTableData />
                </div>
            </div>

        </div>
    )
}
export default OverView;