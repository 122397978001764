import "../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode"; // Assuming you have jwt-decode installed

function HomePage({ loadingBarRef }) {
    const navigate = useNavigate();
    const [cardRef, setCardRef] = useState(null);

    useEffect(() => {
        // Display loading bar
        loadingBarRef.current.continuousStart();
        loadingBarRef.current.complete();

        // Check login status
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            const decodedToken = jwtDecode(accessToken);
            const isTokenExpired = decodedToken.exp * 1000 < new Date().getTime();

            if (isTokenExpired) {
                // Redirect to login page if token is expired
                navigate("/login");
            } else {
                // Redirect to overview page if token is valid
                navigate("/general/overview");
            }
        }
    }, [navigate, loadingBarRef]);

    useEffect(() => {
        const rotateToMouse = (e) => {
            if (cardRef) {
                const bounds = cardRef.getBoundingClientRect();
                const mouseX = e.clientX;
                const mouseY = e.clientY;
                const leftX = mouseX - bounds.x;
                const topY = mouseY - bounds.y;
                const center = {
                    x: leftX - bounds.width / 2,
                    y: topY - bounds.height / 2
                };
                const distance = Math.sqrt(center.x ** 2 + center.y ** 2);

                cardRef.style.transform = `
          rotate3d(
            ${center.y / 100},
            ${-center.x / 100},
            0,
            ${Math.log(distance) * 2}deg
          )
        `;

                const glowElement = cardRef.querySelector('.glow');
                if (glowElement) {
                    glowElement.style.backgroundImage = `
            radial-gradient(
              circle at
              ${center.x * 2 + bounds.width / 2}px
              ${center.y * 2 + bounds.height / 2}px,
              #ffffff55,
              #0000000f
            )
          `;
                }
            }
        };

        const handleMouseEnter = () => {
            if (cardRef) {
                document.addEventListener('mousemove', rotateToMouse);
            }
        };

        const handleMouseLeave = () => {
            if (cardRef) {
                document.removeEventListener('mousemove', rotateToMouse);
                cardRef.style.transform = '';
                const glowElement = cardRef.querySelector('.glow');
                if (glowElement) {
                    glowElement.style.backgroundImage = '';
                }
            }
        };

        if (cardRef) {
            cardRef.addEventListener('mouseenter', handleMouseEnter);
            cardRef.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (cardRef) {
                cardRef.removeEventListener('mouseenter', handleMouseEnter);
                cardRef.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, [cardRef]);

    return (
        <div className="home flex flex-col justify-center items-center h-screen bg-zinc-950 w-full py-12 md:py-24">

            {/* Background layers */}
            <div className="pointer-events-none absolute inset-0 select-none overflow-hidden flex justify-center">
            <video
        src={process.env.PUBLIC_URL + "/assets/media/bg3.mp4"}
        autoPlay
        muted
        loop
        className="absolute w-full h-full object-cover opacity-100"
      ></video>
                <div className="pointer-events-none absolute inset-x-0 top-0 flex justify-center">
                    <div className="shrink-0 fade-in animate-beam-fade opacity-1 translate-y-0" aria-hidden="true">
                        <div className="relative -translate-y-[60px] [mask-image:linear-gradient(black_50%,transparent)] before:absolute before:inset-0 before:bg-gradient-to-r before:from-zinc-950 before:via-transparent before:to-zinc-950">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/aurora-layer-1.webp"}
                                alt=""
                            />
                        </div>
                        <div className="absolute inset-0 translate-x-[60px] [mask-image:linear-gradient(black_50%,transparent)] before:absolute before:inset-0 before:bg-gradient-to-r before:from-zinc-950 before:via-transparent before:to-zinc-950">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/aurora-layer-2.webp"}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Main content */}
            <div className="relative z-10 mx-auto max-w-md px-4 sm:max-w-lg fade-in-up">
                
                <div className="absolute inset-x-4 inset-y-0 -z-10 block rounded-[40px] border-x bg-gradient-to-b from-black/40 shadow-[inset_0_1px_0_rgb(255_255_255/0.08),0_-20px_28px_rgb(0_0_0/0.1),0_-30px_48px_rgb(0_0_0/0.1),0_-40px_96px_rgb(0_0_0/0.1)] backdrop-blur-[2px] [border-image:linear-gradient(transparent,transparent_32px,rgb(255_255_255/0.06)_64px,rgb(255_255_255/0))_1] max-sm:hidden" />
                <div className="isolate">
                    <div className="sm:px-10 sm:pt-10">
                        <div ref={setCardRef} className="card rounded-2xl bg-[rgba(9,9,11,0.97)] bg-[image:radial-gradient(221.02%_101.62%_at_50%_0%,rgba(255,255,255,0.1)_0%,rgba(255,255,255,0)_78.73%)] shadow-[0_2px_20px_rgba(0,0,0,0.5),0_20px_28px_rgba(0,0,0,0.4),inset_0_0.5px_0_rgba(153,253,255,0.1),inset_0_0_0_1px_rgba(255,255,255,0.06)]">
                            <div className="relative flex flex-col items-center overflow-hidden border-b border-dashed border-white/10 pb-14">
                                <div className="mt-4 h-1.5 w-11 rounded bg-black shadow-[0_0.5px_0_rgb(255_255_255/0.075)]" />
                                <div className="absolute inset-0 flex items-start justify-center">
                                    <div className="h-full w-full [mask-image:linear-gradient(black_75%,transparent)]">
                                        <svg
                                            viewBox="0 0 384 449"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="shrink-0"
                                        >
                                            <path
                                                d="M-237.773 351.499C-237.773 351.499 -126.464 147.214 144.19 281.945C414.844 416.676 526.153 212.391 526.153 212.391"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-258.24 10.5493C-258.24 10.5493 -146.931 -193.736 123.723 -59.0046C394.378 75.7266 505.687 -128.559 505.687 -128.559"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-237.394 357.812C-237.394 357.812 -126.085 153.527 144.569 288.258C415.223 422.989 526.532 218.704 526.532 218.704"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-257.859 16.8628C-257.859 16.8628 -146.55 -187.422 124.104 -52.6912C394.758 82.04 506.067 -122.245 506.067 -122.245"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-237.015 364.127C-237.015 364.127 -125.706 159.842 144.948 294.573C415.602 429.304 526.911 225.019 526.911 225.019"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-257.482 23.1772C-257.482 23.1772 -146.173 -181.108 124.481 -46.3767C395.135 88.3545 506.444 -115.931 506.444 -115.931"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-236.636 370.44C-236.636 370.44 -125.327 166.155 145.327 300.886C415.981 435.617 527.29 231.332 527.29 231.332"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-257.101 29.4907C-257.101 29.4907 -145.792 -174.794 124.862 -40.0632C395.516 94.668 506.825 -109.617 506.825 -109.617"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-236.257 376.755C-236.257 376.755 -124.948 172.47 145.706 307.201C416.36 441.932 527.669 237.647 527.669 237.647"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-256.724 35.8051C-256.724 35.8051 -145.415 -168.48 125.239 -33.7488C395.893 100.982 507.202 -103.303 507.202 -103.303"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-235.878 383.068C-235.878 383.068 -124.569 178.783 146.085 313.514C416.739 448.245 528.048 243.96 528.048 243.96"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-256.345 42.1186C-256.345 42.1186 -145.036 -162.166 125.618 -27.4353C396.272 107.296 507.581 -96.9892 507.581 -96.9892"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-235.499 389.383C-235.499 389.383 -124.19 185.098 146.464 319.829C417.118 454.56 528.427 250.275 528.427 250.275"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-255.964 48.4321C-255.964 48.4321 -144.655 -155.853 125.999 -21.1218C396.653 113.609 507.962 -90.6757 507.962 -90.6757"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-235.12 395.696C-235.12 395.696 -123.812 191.411 146.843 326.142C417.497 460.873 528.806 256.588 528.806 256.588"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-255.585 54.7465C-255.585 54.7465 -144.276 -149.539 126.378 -14.8074C397.032 119.924 508.341 -84.3613 508.341 -84.3613"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-234.742 402.009C-234.742 402.009 -123.433 197.724 147.222 332.455C417.876 467.187 529.185 262.901 529.185 262.901"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-255.206 61.06C-255.206 61.06 -143.897 -143.225 126.757 -8.49389C397.411 126.237 508.72 -78.0478 508.72 -78.0478"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-234.363 408.324C-234.363 408.324 -123.054 204.039 147.6 338.77C418.255 473.501 529.563 269.216 529.563 269.216"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-254.827 67.3745C-254.827 67.3745 -143.519 -136.911 127.136 -2.17944C397.79 132.552 509.099 -71.7333 509.099 -71.7333"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-233.984 414.637C-233.984 414.637 -122.675 210.352 147.979 345.083C418.633 479.814 529.942 275.529 529.942 275.529"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-254.449 73.6879C-254.449 73.6879 -143.14 -130.597 127.514 4.13404C398.169 138.865 509.478 -65.4199 509.478 -65.4199"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-233.605 420.952C-233.605 420.952 -122.296 216.667 148.358 351.398C419.012 486.129 530.321 281.844 530.321 281.844"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-254.07 80.0024C-254.07 80.0024 -142.761 -124.283 127.893 10.4485C398.548 145.18 509.856 -59.1054 509.856 -59.1054"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-233.226 427.265C-233.226 427.265 -121.917 222.98 148.737 357.711C419.391 492.442 530.7 288.157 530.7 288.157"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-253.691 86.3159C-253.691 86.3159 -142.382 -117.969 128.272 16.762C398.926 151.493 510.235 -52.7919 510.235 -52.7919"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-232.847 433.58C-232.847 433.58 -121.538 229.295 149.116 364.026C419.77 498.757 531.079 294.472 531.079 294.472"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-253.312 92.6289C-253.312 92.6289 -142.003 -111.656 128.651 23.075C399.305 157.806 510.614 -46.4789 510.614 -46.4789"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-232.468 439.893C-232.468 439.893 -121.159 235.608 149.495 370.339C420.149 505.07 531.458 300.785 531.458 300.785"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-252.933 98.9438C-252.933 98.9438 -141.624 -105.341 129.03 29.3899C399.684 164.121 510.993 -40.164 510.993 -40.164"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-232.087 446.207C-232.087 446.207 -120.778 241.921 149.876 376.653C420.53 511.384 531.839 307.099 531.839 307.099"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-252.552 105.257C-252.552 105.257 -141.243 -99.0283 129.411 35.7029C400.065 170.434 511.374 -33.851 511.374 -33.851"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-231.71 452.521C-231.71 452.521 -120.401 248.236 150.253 382.967C420.907 517.698 532.216 313.413 532.216 313.413"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-252.175 111.572C-252.175 111.572 -140.866 -92.7134 129.788 42.0178C400.442 176.749 511.751 -27.5361 511.751 -27.5361"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-231.329 458.834C-231.329 458.834 -120.02 254.549 150.634 389.281C421.288 524.012 532.597 319.727 532.597 319.727"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-251.794 117.885C-251.794 117.885 -140.485 -86.4004 130.169 48.3308C400.823 183.062 512.132 -21.2231 512.132 -21.2231"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-230.952 465.149C-230.952 465.149 -119.644 260.864 151.011 395.595C421.665 530.326 532.974 326.041 532.974 326.041"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-251.417 124.2C-251.417 124.2 -140.108 -80.0854 130.546 54.6458C401.2 189.377 512.509 -14.9081 512.509 -14.9081"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-230.574 471.463C-230.574 471.463 -119.265 267.178 151.389 401.909C422.044 536.64 533.353 332.355 533.353 332.355"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-251.036 130.513C-251.036 130.513 -139.728 -73.7724 130.927 60.9587C401.581 195.69 512.89 -8.59516 512.89 -8.59516"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-230.195 477.777C-230.195 477.777 -118.886 273.492 151.768 408.223C422.423 542.954 533.731 338.669 533.731 338.669"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-250.659 136.827C-250.659 136.827 -139.351 -67.4585 131.304 67.2727C401.958 202.004 513.267 -2.28119 513.267 -2.28119"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-229.816 484.091C-229.816 484.091 -118.507 279.806 152.147 414.537C422.801 549.268 534.11 344.983 534.11 344.983"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-250.281 143.141C-250.281 143.141 -138.972 -61.1445 131.682 73.5867C402.337 208.318 513.646 4.03277 513.646 4.03277"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-229.435 490.404C-229.435 490.404 -118.126 286.119 152.528 420.85C423.182 555.581 534.491 351.296 534.491 351.296"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-249.9 149.455C-249.9 149.455 -138.591 -54.8306 132.063 79.9006C402.717 214.632 514.026 10.3467 514.026 10.3467"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-229.058 496.719C-229.058 496.719 -117.749 292.434 152.905 427.165C423.559 561.896 534.868 357.611 534.868 357.611"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-249.523 155.769C-249.523 155.769 -138.214 -48.5166 132.44 86.2146C403.094 220.946 514.403 16.6607 514.403 16.6607"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-228.677 503.032C-228.677 503.032 -117.368 298.747 153.286 433.478C423.94 568.209 535.249 363.924 535.249 363.924"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-249.142 162.082C-249.142 162.082 -137.833 -42.2026 132.821 92.5286C403.475 227.26 514.784 22.9747 514.784 22.9747"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-228.3 509.347C-228.3 509.347 -116.991 305.062 153.663 439.793C424.317 574.524 535.626 370.239 535.626 370.239"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-248.765 168.396C-248.765 168.396 -137.456 -35.8887 133.198 98.8425C403.852 233.574 515.161 29.2886 515.161 29.2886"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-227.919 515.66C-227.919 515.66 -116.61 311.375 154.044 446.106C424.698 580.837 536.007 376.552 536.007 376.552"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-248.384 174.71C-248.384 174.71 -137.075 -29.5747 133.579 105.157C404.233 239.888 515.542 35.6026 515.542 35.6026"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-227.542 521.974C-227.542 521.974 -116.233 317.689 154.421 452.42C425.075 587.151 536.384 382.866 536.384 382.866"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-248.005 181.023C-248.005 181.023 -136.696 -23.2617 133.958 111.469C404.612 246.201 515.921 41.9156 515.921 41.9156"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-227.161 528.288C-227.161 528.288 -115.853 324.002 154.802 458.734C425.456 593.465 536.765 389.18 536.765 389.18"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-247.628 187.338C-247.628 187.338 -136.319 -16.9468 134.335 117.784C404.989 252.516 516.298 48.2305 516.298 48.2305"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-226.783 534.601C-226.783 534.601 -115.474 330.316 155.181 465.047C425.835 599.778 537.144 395.493 537.144 395.493"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-247.247 193.651C-247.247 193.651 -135.938 -10.6338 134.716 124.097C405.37 258.829 516.679 54.5435 516.679 54.5435"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-226.404 540.915C-226.404 540.915 -115.095 336.63 155.559 471.362C426.214 606.093 537.522 401.808 537.522 401.808"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-246.87 199.966C-246.87 199.966 -135.562 -4.31932 135.093 130.412C405.747 265.143 517.056 60.858 517.056 60.858"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-226.025 547.229C-226.025 547.229 -114.716 342.944 155.938 477.675C426.592 612.406 537.901 408.121 537.901 408.121"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-246.49 206.279C-246.49 206.279 -135.181 1.99416 135.473 136.725C406.128 271.457 517.437 67.1714 517.437 67.1714"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-225.646 553.543C-225.646 553.543 -114.337 349.258 156.317 483.99C426.971 618.721 538.28 414.436 538.28 414.436"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-246.113 212.594C-246.113 212.594 -134.804 8.3091 135.85 143.04C406.505 277.771 517.813 73.4864 517.813 73.4864"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-225.267 559.857C-225.267 559.857 -113.958 355.572 156.696 490.303C427.35 625.034 538.659 420.749 538.659 420.749"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-245.732 218.907C-245.732 218.907 -134.423 14.6221 136.231 149.353C406.885 284.084 518.194 79.7994 518.194 79.7994"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-224.888 566.171C-224.888 566.171 -113.579 361.886 157.075 496.617C427.729 631.349 539.038 427.064 539.038 427.064"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-245.353 225.221C-245.353 225.221 -134.044 20.9356 136.61 155.667C407.264 290.398 518.573 86.1128 518.573 86.1128"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-224.509 572.485C-224.509 572.485 -113.2 368.2 157.454 502.931C428.108 637.662 539.417 433.377 539.417 433.377"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-244.974 231.535C-244.974 231.535 -133.665 27.25 136.989 161.981C407.643 296.712 518.952 92.4273 518.952 92.4273"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-224.128 578.798C-224.128 578.798 -112.819 374.513 157.835 509.244C428.489 643.976 539.798 439.69 539.798 439.69"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-244.595 237.849C-244.595 237.849 -133.286 33.5635 137.368 168.295C408.022 303.026 519.331 98.7408 519.331 98.7408"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-223.751 585.113C-223.751 585.113 -112.442 380.828 158.212 515.559C428.866 650.29 540.175 446.005 540.175 446.005"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-244.216 244.163C-244.216 244.163 -132.907 39.8779 137.747 174.609C408.401 309.34 519.71 105.055 519.71 105.055"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-223.37 591.426C-223.37 591.426 -112.062 387.141 158.593 521.872C429.247 656.604 540.556 452.318 540.556 452.318"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-243.837 250.477C-243.837 250.477 -132.528 46.1914 138.126 180.923C408.78 315.654 520.089 111.369 520.089 111.369"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-222.993 597.741C-222.993 597.741 -111.685 393.456 158.97 528.187C429.624 662.918 540.933 458.633 540.933 458.633"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-243.458 256.791C-243.458 256.791 -132.149 52.5059 138.505 187.237C409.159 321.968 520.468 117.683 520.468 117.683"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-222.615 604.054C-222.615 604.054 -111.306 399.769 159.348 534.5C430.003 669.231 541.312 464.946 541.312 464.946"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-243.079 263.104C-243.079 263.104 -131.77 58.8194 138.884 193.551C409.538 328.282 520.847 123.997 520.847 123.997"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-222.236 610.369C-222.236 610.369 -110.927 406.084 159.727 540.815C430.382 675.546 541.69 471.261 541.69 471.261"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-242.699 269.418C-242.699 269.418 -131.39 65.1328 139.264 199.864C409.919 334.595 521.228 130.31 521.228 130.31"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-221.857 616.682C-221.857 616.682 -110.548 412.397 160.106 547.128C430.76 681.859 542.069 477.574 542.069 477.574"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-242.322 275.732C-242.322 275.732 -131.013 71.4473 139.641 206.178C410.296 340.91 521.604 136.625 521.604 136.625"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-221.476 622.996C-221.476 622.996 -110.167 418.71 160.487 553.442C431.141 688.173 542.45 483.888 542.45 483.888"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-241.941 282.045C-241.941 282.045 -130.632 77.7603 140.022 212.491C410.676 347.223 521.985 142.938 521.985 142.938"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-221.099 629.31C-221.099 629.31 -109.79 425.024 160.864 559.756C431.518 694.487 542.827 490.202 542.827 490.202"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-241.564 288.36C-241.564 288.36 -130.255 84.0752 140.399 218.806C411.053 353.538 522.362 149.252 522.362 149.252"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-220.718 635.623C-220.718 635.623 -109.409 431.338 161.245 566.07C431.899 700.801 543.208 496.516 543.208 496.516"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-241.183 294.673C-241.183 294.673 -129.874 90.3882 140.78 225.119C411.434 359.851 522.743 155.565 522.743 155.565"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-220.341 641.938C-220.341 641.938 -109.032 437.653 161.622 572.384C432.276 707.115 543.585 502.83 543.585 502.83"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-240.806 300.988C-240.806 300.988 -129.497 96.7031 141.157 231.434C411.811 366.166 523.12 161.88 523.12 161.88"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-219.96 648.251C-219.96 648.251 -108.651 443.966 162.003 578.698C432.657 713.429 543.966 509.144 543.966 509.144"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-240.425 307.301C-240.425 307.301 -129.116 103.016 141.538 237.747C412.192 372.479 523.501 168.193 523.501 168.193"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-219.583 654.565C-219.583 654.565 -108.274 450.28 162.38 585.011C433.034 719.743 544.343 515.458 544.343 515.458"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-240.046 313.615C-240.046 313.615 -128.737 109.33 141.917 244.061C412.571 378.792 523.88 174.507 523.88 174.507"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-219.204 660.879C-219.204 660.879 -107.895 456.594 162.759 591.325C433.413 726.057 544.722 521.772 544.722 521.772"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-239.667 319.929C-239.667 319.929 -128.358 115.644 142.296 250.375C412.95 385.106 524.259 180.821 524.259 180.821"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-218.824 667.192C-218.824 667.192 -107.515 462.907 163.139 597.638C433.794 732.37 545.103 528.085 545.103 528.085"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-239.288 326.243C-239.288 326.243 -127.979 121.958 142.675 256.689C413.329 391.42 524.638 187.135 524.638 187.135"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-218.447 673.507C-218.447 673.507 -107.138 469.222 163.516 603.953C434.171 738.685 545.479 534.399 545.479 534.399"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-238.909 332.557C-238.909 332.557 -127.601 128.272 143.054 263.003C413.708 397.734 525.017 193.449 525.017 193.449"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-238.531 338.871C-238.531 338.871 -127.222 134.586 143.432 269.317C414.087 404.048 525.396 199.763 525.396 199.763"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                            <path
                                                d="M-238.154 345.186C-238.154 345.186 -126.845 140.9 143.809 275.632C414.464 410.363 525.772 206.078 525.772 206.078"
                                                stroke="white"
                                                strokeOpacity="0.05"
                                                strokeWidth="0.5"
                                            />
                                        </svg>
                                    </div>

                                </div>
                                <div className="mt-10">
                                    <span className="sr-only">SaviartMedia</span>
                                    <img
                                        src={process.env.PUBLIC_URL + "/assets/images/home-logo.svg"}
                                        alt=""
                                    />
                                </div>
                                <div className="relative flex flex-col  justify-center items-center mt-10 text-center font-display font-semibold uppercase tracking-[0.2em]">

                                    <time
                                        dateTime="2023-06-20T18:00-04:00"
                                        className="flex items-center text-[0.8125rem]/6 text-zinc-300"
                                    >
                                        Since April 20, 2019
                                    </time>
                                    <div className="mt-1 text-2xs leading-6 text-zinc-500">
                                        Offerwall Management System
                                    </div>

                                </div>
                            </div>
                            <div className="flex items-center justify-between overflow-hidden p-4 font-mono text-[.6875rem]/4 font-bold text-zinc-500 md:p-3">
                                <div className="text-left w-1/3">
                                    <div>
                                        <a
                                            href="#"
                                            className="transition hover:text-zinc-400"
                                        >
                                            Sơn Nguyễn
                                        </a>
                                    </div>
                                    <div className="mt-0.5 text-zinc-700">
                                        <a
                                            href="https://www.facebook.com/blue.outtatime/"
                                            className="transition hover:text-zinc-400"
                                            target="_blank"
                                        >
                                            Facebook
                                        </a>
                                        /
                                        <a
                                            href="https://t.me/saviart1909"
                                            className="transition hover:text-zinc-400"
                                            target="_blank"
                                        >
                                            Telegram
                                        </a>
                                    </div>
                                </div>
                                <a href="/login" className=" relative flex justify-center items-center w-1/3 cursor-pointer">

                                    <div className="relative" aria-hidden="true">

                                        <img className="opacity-20 hover:opacity-80"
                                            src={process.env.PUBLIC_URL + "/assets/images/qr-code.svg"}
                                            alt=""
                                        />

                                        <div className="absolute z-10 h-px w-[100%] bg-gradient-to-r from-[#38BDF8]/50 via-[#67caf5]/100 to-[#38BDF8]/50 shadow-blue-glow animate-qrscan">
                                        </div>
                                    </div>
                                </a>

                                <div className="text-right  w-1/3">
                                    <div>Contact</div>
                                    <div className="mt-0.5 text-zinc-700">+84335190991</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default HomePage;
