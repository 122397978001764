import React, { useState, useEffect } from 'react';
import customaxios from '../../services/axios';

function WhitelistIPManagerment() {
    const [whitelistIPs, setWhitelistIPs] = useState([]);

    useEffect(() => {
        fetchWhitelistIPs();
    }, []);

    const fetchWhitelistIPs = async () => {
        try {
            const response = await customaxios.get('/api/whitelistip/');
            setWhitelistIPs(response);
        } catch (error) {
            console.error('Error fetching whitelist IPs:', error);
        }
    };

    const handleDeleteIP = async (ipId) => {
        try {
            await customaxios.delete(`/api/whitelistip/${ipId}/`);
            const updatedWhitelistIPs = whitelistIPs.filter(item => item.id !== ipId);
            setWhitelistIPs(updatedWhitelistIPs);
        } catch (error) {
            console.error('Error deleting whitelist IP:', error);
        }
    };

    const handleToggleActive = async (ipId, active) => {
        try {
            await customaxios.put(`/api/whitelistip/${ipId}/`, { active: !active });
            const updatedWhitelistIPs = whitelistIPs.map(item => {
                if (item.id === ipId) {
                    return { ...item, active: !active };
                }
                return item;
            });
            setWhitelistIPs(updatedWhitelistIPs);
        } catch (error) {
            console.error('Error toggling whitelist IP active status:', error);
        }
    };

    return (
        <div className="mt-12 mt-20 mb-6">
            <h2 className="text-xl font-semibold mb-4">Whitelist IP Management</h2>
            <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            IP
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Active
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Note
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {whitelistIPs.map((ip, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{ip.ip}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <input
                                    type="checkbox"
                                    checked={ip.active}
                                    onChange={() => handleToggleActive(ip.id, ip.active)}
                                    className="rounded border-gray-300"
                                />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{ip.note}</td>
                            
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default WhitelistIPManagerment;
