import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

// Function to normalize data
const normalizeData = (data) => {
  // Get all unique dates
  const allDates = [...new Set(data.map(entry => entry.date))];
  allDates.sort((a, b) => new Date(a) - new Date(b));

  // Normalize revenue and account data for each date
  const normalizedData = allDates.map(date => {
    const entry = data.find(d => d.date === date);
    return {
      date,
      revenue: entry ? entry.revenue : 0,
      account: entry ? entry.account : 0,
    };
  });

  return normalizedData;
};

const WorkTrendChart = () => {
  // Use selector from Redux to get data
  const rawData = useSelector(state => state.charts.workTrendChartData);

  // Normalize the raw data
  const normalizedData = normalizeData(rawData);

  const seriesData = [
    {
      name: 'Thu Nhập',
      type: 'area',
      data: normalizedData.map(data => data.revenue),
    },
    {
      name: 'Tài Khoản',
      type: 'area',
      data: normalizedData.map(data => data.account),
    },
  ];

  const xaxisConfig = {
    type: 'category',
    categories: normalizedData.map(data => data.date),
  };

  const chartOptions = {
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      height: 320,
      toolbar: {
        show: false,
      },
    },
    colors: ['#00E396', '#2a77f4'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: [1.5, 1.5],
      dashArray: [0, 5],
      lineCap: 'round',
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
      strokeDashArray: 3,
    },
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.1,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 0.4,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    tooltip: {
      x: {
        format: "dd MMM 'yy",
      },
      y: {
        formatter: function (value, { seriesName }) {
          if (seriesName === 'Thu Nhập') {
            return `$${value}`; // Chỉ thêm ký tự $ cho Thu Nhập
          }
          return value; // Trả về giá trị nguyên bản cho Tài Khoản
        },
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: xaxisConfig,
  };

  return (
    <div className='bg-white rounded-sm'>
      <div className='border-b p-3 border-gray-200 text-sm font-semibold'>
        Thống Kê Tình Hình Làm Việc Theo Ngày
      </div>
      <ReactApexChart
        className="bg-white rounded-lg"
        options={chartOptions}
        series={seriesData}
        height="350"
      />
    </div>
  );
};

export default WorkTrendChart;
