import React from 'react';
import { useSelector } from 'react-redux';

const extractMiddleDomain = (domain) => {
    const parts = domain.split('.');
    return parts.length === 3 ? parts[1] : domain;
};

const SitesTableData = () => {
    const sitesTableData = useSelector(state => state.table.sitesTableData);

    return (
        <>
            {sitesTableData.map((siteData, siteIndex) => (
                <div key={siteIndex} className='bg-white rounded-sm my-4'>
                    <div className='border-b p-1 border-gray-200 p-3 text-sm text-center uppercase font-semibold'>
                        {extractMiddleDomain(siteData.site)}
                    </div>
                    <table className='w-full divide-y divide-gray-200'>
                        <thead className='bg-gray-50'>
                            <tr>
                                <th scope='col' className='px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider'>
                                    Network
                                </th>
                                <th scope='col' className='px-3 py-3 text-center text-xs font-bold text-gray-500 uppercase tracking-wider'>
                                    Total Coins
                                </th>
                            </tr>
                        </thead>
                        <tbody className='bg-white divide-y divide-gray-200'>
                            {siteData.networks.map((network, networkIndex) => (
                                <tr key={networkIndex}>
                                    <td className='px-6 py-2 whitespace-nowrap text-sm text-center font-medium text-gray-900'>{network.network}</td>
                                    <td className='px-6 py-2 whitespace-nowrap text-sm text-center text-gray-500'>${network.total_coins}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}
        </>
    );
};

export default SitesTableData;
