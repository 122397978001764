import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHistoryTransactionData } from '../../services/appsSlice';
import moment from 'moment';
import axios from '../../services/axios';

function HistoryTransactions() {
    const dispatch = useDispatch();
    const { startDate, endDate } = useSelector((state) => state.dateRange);
    const historyTransactionData = useSelector((state) => state.apps.historyTransactionData);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'history_timecreated', direction: 'descending' });
    const [filters, setFilters] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const pageSize = 100;

    useEffect(() => {
        dispatch(fetchHistoryTransactionData({ startDate, endDate }));
    }, [dispatch, startDate, endDate]);

    const sortedAndFilteredData = useMemo(() => {
        return historyTransactionData.filter((item) =>
            Object.keys(filters).every((key) =>
                filters[key] ? item[key]?.toLowerCase().includes(filters[key].toLowerCase()) : true
            )
        ).sort((a, b) => {
            if (!sortConfig.key) return 0;
            const order = sortConfig.direction === 'ascending' ? 1 : -1;
            return (`${a[sortConfig.key]}`.localeCompare(`${b[sortConfig.key]}`, 'en', { numeric: true })) * order;
        });
    }, [historyTransactionData, sortConfig, filters]);

    const handleSort = (key) => {
        setSortConfig({
            key,
            direction: sortConfig.key === key && sortConfig.direction === 'ascending' ? 'descending' : 'ascending',
        });
    };

    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => prevPage + direction);
    };

    const handleChangeFilter = (key, value) => {
        setFilters({ ...filters, [key]: value });
    };




    const handleOpenDeleteModal = (id) => {
        setShowDeleteModal(true);
        setDeleteId(id);
    };

    const handleDelete = async () => {
        if (deleteId) {
            try {
                await axios.delete(`api/v1/history/${deleteId}/`);

                setShowDeleteModal(false);
                dispatch(fetchHistoryTransactionData({ startDate, endDate }));
            } catch (error) {
                console.error('Error deleting transaction:', error);

            }
        }
    };
    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false);
        setDeleteId(null);
    };


  


    const columns = [
        { key: 'history_timecreated', name: 'Time Created', format: value => moment(value).format('DD/MM/YYYY HH:mm') },
        { key: 'history_username', name: 'MemberName' },
        { key: 'history_id', name: 'User ID' },
        { key: 'history_site', name: 'Site' },
        { key: 'history_network', name: 'Network' },
        { key: 'history_coins', name: 'Coins' },
        { key: 'history_offername', name: 'Offer Name' },
        { key: 'history_ip', name: 'IP' },
        { key: 'history_geo', name: 'Geo' },
        { key: 'history_platform', name: 'Platform' },

    ];

    const totalRecords = sortedAndFilteredData.length;
    const displayedRecordsStart = (currentPage - 1) * pageSize + 1;
    const displayedRecordsEnd = Math.min(displayedRecordsStart + pageSize - 1, totalRecords);
    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        return sortedAndFilteredData.slice(startIndex, startIndex + pageSize);
    }, [sortedAndFilteredData, currentPage]);

    return (
        <div className="mt-20 max-w-full overflow-x-auto">
            <div className='rounded-sm justify-center items-center'>
                <div className="pl-5 mb-10 ">
                    <div className="font-semibold">Danh Sách Trả Điểm</div>
                    <div className="text-xs">Thống kê kết quả chung của công việc</div>
                </div>
                {/* Pagination Controls */}
                <div className="flex justify-between items-center m-4 text-sm">
                    <span className="text-xs">
                        Displaying {displayedRecordsStart}-{displayedRecordsEnd} of {totalRecords} records
                    </span>
                    <div className="flex items-center space-x-2">
                        <button
                            onClick={() => handlePageChange(-1)}
                            disabled={currentPage === 1}
                            className="px-4 py-1 text-sm font-medium text-white bg-blue-300 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            Previous
                        </button>
                        <span className="px-4 py-1 text-sm font-semibold text-gray-700 bg-gray-100 rounded-md">
                            {currentPage}
                        </span>
                        <button
                            onClick={() => handlePageChange(1)}
                            disabled={currentPage * pageSize >= sortedAndFilteredData.length}
                            className="px-4 py-1 text-sm font-medium text-white bg-blue-300 rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        >
                            Next
                        </button>
                    </div>
                </div>

                <table className="max-w-full divide-y divide-gray-200 table-fixed text-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={column.key}
                                    className="px-3 py-3 text-left text-xs text-gray-500 whitespace-nowrap uppercase tracking-wider cursor-pointer border border-gray-200"
                                    onClick={() => handleSort(column.key)}
                                >
                                    {column.name}
                                </th>
                            ))}
                            <th
                                key="action"
                                className="px-3 py-3 text-left text-xs text-gray-500 whitespace-nowrap uppercase tracking-wider cursor-pointer border border-gray-200"
                            >
                                Action
                            </th>
                        </tr>
                        <tr>
                            {columns.map((column) => (
                                <th
                                    key={`filter-${column.key}`}
                                    className="px-1 py-1 border border-gray-200"
                                >
                                    <div className='flex items-center gap-1'>
                                        <svg width="11" height="11" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path d="m9.14375,1.83333l-4.39688,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-0.91875,0a0.45938,0.48125 0 0 0 -0.48125,0.45833a0.45938,0.48125 0 0 0 0.48125,0.45833l0.91875,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l4.39688,0a0.45938,0.48125 0 0 0 0.48125,-0.45833a0.45938,0.48125 0 0 0 -0.48125,-0.45833z" />
                                                <path d="m9.14375,5.04167l-0.89688,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-4.41875,0a0.4375,0.45833 0 1 0 0,0.91667l4.41875,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l0.89688,0a0.4375,0.45833 0 1 0 0,-0.91667z" />
                                                <path d="m9.14375,8.25l-3.74063,0a1.35625,1.42083 0 0 0 -1.24688,-0.91667a1.3125,1.375 0 0 0 -1.225,0.91667l-1.575,0a0.4375,0.45833 0 1 0 0,0.91667l1.575,0a1.3125,1.375 0 0 0 1.225,0.91667a1.35625,1.42083 0 0 0 1.24688,-0.91667l3.74063,0a0.4375,0.45833 0 1 0 0,-0.91667z" />
                                            </g>
                                        </svg>
                                        <input
                                            type="text"
                                            className="w-full text-sm font-normal rounded border border-gray-300 focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 outline-none"
                                            onChange={(e) => handleChangeFilter(column.key, e.target.value)}
                                        />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {paginatedData.map((row, index) => (
                            <tr key={index} className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700'>
                                {columns.map((column) => (
                                    <td key={column.key} className="px-3 py-3 whitespace-nowrap text-ellipsis overflow-hidden text-xs border border-gray-200">
                                        {column.format ? column.format(row[column.key]) : row[column.key]}
                                    </td>
                                ))}
                                <td className="px-3 py-3 whitespace-nowrap text-ellipsis overflow-hidden text-exs border border-gray-200">
                                    <button className="text-red-500 hover:text-red-700" onClick={() => handleOpenDeleteModal(row.id)}>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                                    </button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                {showDeleteModal && (
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                            </div>
                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                {/* Modal content */}
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                                Delete Transaction
                                            </h3>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Are you sure you want to delete this transaction? This action cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleDelete}>
                                        Delete
                                    </button>
                                    <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleCloseDeleteModal}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </div>

    );
}

export default HistoryTransactions;