import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

function Chatbox() {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]); // Danh sách tin nhắn
    const ws = useRef(null);
    const currentUser = localStorage.getItem('username');

    const connectWebSocket = () => {
        ws.current = new WebSocket('wss://saviartmedia.com/ws/chat/');
        ws.current.onopen = () => console.log('WebSocket Connected');
        ws.current.onerror = (error) => console.log('WebSocket Error:', error);
        ws.current.onclose = () => {
            console.log('WebSocket Disconnected. Attempting to reconnect...');
            setTimeout(connectWebSocket, 5000); // Try to reconnect after 5 seconds
        };
        ws.current.onmessage = e => {
            const data = JSON.parse(e.data);
            if (Array.isArray(data)) {
                setMessages(data);
            } else {
                setMessages(prevMessages => [...prevMessages, data]);
            }
        };
    };

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);

    const toggleChat = () => setIsOpen(!isOpen);

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };
    const handleKeyDown = (e) => {
        // Kiểm tra xem người dùng có nhấn Ctrl + Enter hay không
        if (e.key === 'Enter') {
            handleSendMessage();
            e.preventDefault(); // Ngăn không cho thêm dòng mới sau khi gửi
        }
    };
    const handleSendMessage = () => {
        if (message && ws.current) {
            const username = localStorage.getItem('username') || 'Anonymous';
            const data = { username, message };  // Đảm bảo rằng bạn thêm 'username' vào data gửi đi
            ws.current.send(JSON.stringify(data));
            setMessage("");
        }
    };

    return (
        <div className={`fixed bottom-10 right-0 md:w-3/12 w-full h-1/2 transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-[calc(100%-2rem)]'}`}>
            <div onClick={toggleChat} className="fixed cursor-pointer rounded-s-2xl  bottom-[-45px] left-0 w-[2rem] h-[5rem]shadow-md flex justify-center items-center text-exs scale-105 transition duration-300">
                <div className="select-none">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.221 27.4295L5.10677 27.7445" stroke="#222222" strokeWidth="2" strokeLinecap="round" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.78614 27.7095L26.5416 27.4645L26.321 10.569C26.3063 9.44901 26.299 8.88901 26.0755 8.46407C25.8788 8.09028 25.5689 7.78834 25.1901 7.60153C24.7594 7.38915 24.1994 7.39646 23.0794 7.41109L10.7234 7.57249C9.60337 7.58712 9.04337 7.59443 8.61843 7.81799C8.24464 8.01463 7.9427 8.32456 7.75589 8.70336C7.5435 9.13399 7.55082 9.694 7.56545 10.814L7.78614 27.7095ZM21.0433 17.8695L15.4187 17.8695L17.731 20.1818C18.1215 20.5723 18.1215 21.2055 17.731 21.596C17.3405 21.9865 16.7073 21.9865 16.3168 21.596L12.4395 17.7187C12.4145 17.6937 12.3908 17.6678 12.3684 17.6412C12.1462 17.4577 12.0045 17.1802 12.0045 16.8695C12.0045 16.5589 12.1462 16.2813 12.3684 16.0979C12.3908 16.0712 12.4145 16.0453 12.4395 16.0203L16.3168 12.143C16.7073 11.7525 17.3405 11.7525 17.731 12.143C18.1215 12.5336 18.1215 13.1667 17.731 13.5572L15.4187 15.8695L21.0433 15.8695C21.5956 15.8695 22.0433 16.3172 22.0433 16.8695C22.0433 17.4218 21.5956 17.8695 21.0433 17.8695Z" fill="#222222" />
                    </svg>

                </div>
            </div>
            <div className='message-container h-full rounded-md bg-white ml-8 shadow-md mt-16 overflow-auto'>
                <div className="flex-1 p-2 sm:p-6 justify-between flex flex-col h-full">
                    <div className="flex sm:items-center justify-between py-1 border-b-2 border-gray-200 w-full fixed">
                        <div className="text-sm font-semibold text-gray-900">NOTE</div>
                        <div className="flex items-center space-x-2">
                            {/* Action buttons or any other content */}
                        </div>
                    </div>
                    <div id="messages" className="mt-12 flex flex-col space-y-2 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                        {messages.map((msg, index) => (
                            <div key={index} className={`flex ${msg.username === currentUser ? 'justify-end' : 'justify-start'}`}>
                                {msg.username !== currentUser &&
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.17157 3.17157C2 4.34315 2 6.22876 2 10V14C2 17.7712 2 19.6569 3.17157 20.8284C3.82496 21.4818 4.70043 21.7708 6.00121 21.8986C6.066 19.184 8.72694 17 12 17C15.2731 17 17.934 19.184 17.9988 21.8986C19.2996 21.7708 20.175 21.4818 20.8284 20.8284C22 19.6569 22 17.7712 22 14V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C6.22876 2 4.34315 2 3.17157 3.17157ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" fill="#7E869E" fillOpacity="0.25" />
                                        <path d="M17.9407 21.2989C17.7395 20.1146 17.0355 19.0298 15.9565 18.2411C14.8775 17.4524 13.495 17.0122 12.0599 17.0002C10.6247 16.9883 9.23198 17.4055 8.13433 18.176C7.03668 18.9466 6.30685 20.0196 6.07726 21.2002" stroke="#222222" strokeWidth="1.2" />
                                        <circle cx="12" cy="10" r="3" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
                                        <rect x="2.6" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="#222222" strokeWidth="1.2" />
                                    </svg>

                                }
                                <div className={`flex flex-col space-y-2 text-xs max-w-xs mx-2 ${msg.username === currentUser ? 'items-end' : 'items-start'}`}>
                                    <div>
                                        <span className={`px-2 py-2 rounded-lg inline-block ${msg.username === currentUser ? 'rounded-br-none bg-blue-300 text-black' : 'rounded-bl-none bg-gray-300 text-gray-600'}`}>
                                            <div className="text-xs font-semibold flex justify-end items-end">{msg.username}</div>
                                            {msg.message}
                                        </span>
                                        <div className="text-exss text-gray-300 mt-1">
                                            {moment(msg.timestamp).format('DD-MM-YYYY HH:mm:ss')}
                                        </div>
                                    </div>
                                </div>
                                {msg.username === currentUser &&
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M3.17157 3.17157C2 4.34315 2 6.22876 2 10V14C2 17.7712 2 19.6569 3.17157 20.8284C3.82496 21.4818 4.70043 21.7708 6.00121 21.8986C6.066 19.184 8.72694 17 12 17C15.2731 17 17.934 19.184 17.9988 21.8986C19.2996 21.7708 20.175 21.4818 20.8284 20.8284C22 19.6569 22 17.7712 22 14V10C22 6.22876 22 4.34315 20.8284 3.17157C19.6569 2 17.7712 2 14 2H10C6.22876 2 4.34315 2 3.17157 3.17157ZM12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" fill="#7E869E" fillOpacity="0.25" />
                                        <path d="M17.9407 21.2989C17.7395 20.1146 17.0355 19.0298 15.9565 18.2411C14.8775 17.4524 13.495 17.0122 12.0599 17.0002C10.6247 16.9883 9.23198 17.4055 8.13433 18.176C7.03668 18.9466 6.30685 20.0196 6.07726 21.2002" stroke="#222222" strokeWidth="1.2" />
                                        <circle cx="12" cy="10" r="3" stroke="#222222" strokeWidth="1.2" strokeLinecap="round" />
                                        <rect x="2.6" y="2.6" width="18.8" height="18.8" rx="3.4" stroke="#222222" strokeWidth="1.2" />
                                    </svg>

                                }
                            </div>
                        ))}
                        <div ref={messagesEndRef => messagesEndRef && messagesEndRef.scrollIntoView({ behavior: "smooth" })} />
                    </div>
                    <div className="border-t-2 border-gray-200 px-2 pt-2 mb-2 sm:mb-0">
                        <div className="flex gap-2 justify-center items-center mb-2">
                            <textarea
                                value={message}
                                onChange={handleMessageChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Write your message!"
                                className="w-full px-2 py-2 text-xs h-8 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600  bg-gray-200 rounded-md resize-none" // Đã thêm resize-none để ngăn người dùng thay đổi kích thước
                            ></textarea>
                            <button type="button" onClick={handleSendMessage} className="inline-flex text-xs items-center justify-center rounded-lg px-2 py-2 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none">
                                <span className="">Send</span>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-4 w-4 ml-2 transform rotate-90">
                                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chatbox;
