import { fetchFastproxyData } from '../../../services/proxySlice';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import axioscustome from '../../../services/axios';

function FastProxyControl() {
  const dispatch = useDispatch();

  // Trạng thái lưu trữ dữ liệu proxy từ Redux store
  const fastproxyData = useSelector((state) => state.proxy.fastproxyData);
  const loading = useSelector((state) => state.proxy.loading);
  const error = useSelector((state) => state.proxy.error);

  // Các trạng thái cục bộ để quản lý tương tác UI
  const [currentSelections, setCurrentSelections] = useState({});
  const [lastChangeTimes, setLastChangeTimes] = useState({});
  const [buttonColors, setButtonColors] = useState({});
  const [selectColors, setSelectColors] = useState({}); // Sử dụng một đối tượng để lưu trữ màu sắc cho từng port
  const [errorMessages, setErrorMessages] = useState({});
  const [showError, setShowError] = useState(false);
  const [clickedPorts, setClickedPorts] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  // Danh sách các quốc gia có sẵn cho lựa chọn
  const countryOptions = [
    "AD", "AE", "AF", "AG", "AI", "AL", "ALL", "AM", "AN", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB",
    "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA",
    "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ",
    "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD",
    "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT",
    "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM",
    "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD",
    "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ",
    "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL",
    "PM", "PN", "PR", "PT", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI",
    "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK",
    "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI",
    "VN", "VU", "WF", "WS", "XK", "YE", "ZA", "ZM", "ZW"
  ];
  
  // Xử lý sự kiện thay đổi lựa chọn Geo của người dùng
  const handleSelectChange = (e, proxyId, keyport, port) => {
    const selectedGeo = e.target.value;
    setCurrentSelections({ ...currentSelections, [proxyId]: selectedGeo });
    handleGeoChange(proxyId, keyport, port, selectedGeo, proxyId);
  };

  // Gửi yêu cầu thay đổi Geo đến API và cập nhật UI tương ứng
  const handleGeoChange = async (proxyId, keyport, port, geo, messageDivId) => {
    const apiUrl = `https://api-socks.fastproxy.vip/api/v1/proxies/${keyport}/geo?api_key=zWBECPv4wA307oB1`;
    try {
      const response = await axios.post(apiUrl, {
        port: port,
        geo: geo,
      });
      if (response.data.success) {
        const currentTime = new Date().toISOString();
        const updateUrl = `/api/v1/fastproxy/${proxyId}/`;
        await axioscustome.put(updateUrl, {
          proxy_lastchangeip: currentTime,
          proxy_country: geo
        });
        setLastChangeTimes((prevTimes) => ({ ...prevTimes, [proxyId]: currentTime }));
        setSelectColors((prevColors) => ({ ...prevColors, [port]: "green" }));
        setTimeout(() => {
          setSelectColors((prevColors) => ({ ...prevColors, [port]: "default" }));
        }, 5000);
      } else {
        setErrorMessages({ ...errorMessages, [messageDivId]: response.data.error });
        setSelectColors((prevColors) => ({ ...prevColors, [port]: "red" }));
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setSelectColors((prevColors) => ({ ...prevColors, [port]: "default" }));
        }, 5000);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessages({ ...errorMessages, [messageDivId]: "Error in changing GEO" });
      setSelectColors((prevColors) => ({ ...prevColors, [port]: "red" }));
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setSelectColors((prevColors) => ({ ...prevColors, [port]: "default" }));
      }, 5000);
    }
  };

  // Xử lý yêu cầu xoay IP thông qua API và cập nhật UI
  const handleRotateIP = async (proxyId, proxyPort, proxyKeyport) => {
    const apiUrl = `https://api-socks.fastproxy.vip/api/v1/proxies/${proxyKeyport}/ip?port=${proxyPort}&api_key=zWBECPv4wA307oB1`;
    try {
      const response = await axios.get(apiUrl);

      if (response.data.success) {
        const currentTime = new Date().toISOString();
        const updateUrl = `/api/v1/fastproxy/${proxyId}/`;
        await axioscustome.put(updateUrl, { proxy_lastchangeip: currentTime });
        setLastChangeTimes((prevTimes) => ({ ...prevTimes, [proxyId]: currentTime }));
        setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "green" }));
        // Hiển thị thông báo lỗi trong 5 giây sau đó ẩn đi
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "default" }));
        }, 5000);
      } else {
        setErrorMessages({ ...errorMessages, [proxyId]: response.data.error });
        setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "red" }));
        // Hiển thị thông báo lỗi trong 5 giây sau đó ẩn đi
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
          setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "default" }));

        }, 5000);
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessages({ ...errorMessages, [proxyId]: "Error in changing IP" });
      setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "red" }));
      // Hiển thị thông báo lỗi trong 5 giây sau đó ẩn đi
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
        setButtonColors((prevColors) => ({ ...prevColors, [proxyId]: "default" }));
      }, 5000);
    }
    setClickedPorts(prevClickedPorts => [...prevClickedPorts, proxyId]);
  };

  useEffect(() => {
    dispatch(fetchFastproxyData());
  }, [dispatch]);

  useEffect(() => {
    setDisplayData(fastproxyData);
  }, [fastproxyData]);

  const handleSortProxies = () => {
    const sortedData = [...fastproxyData].sort((a, b) => clickedPorts.includes(b.id) - clickedPorts.includes(a.id));
    setDisplayData(sortedData);
  };

  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    // Cập nhật currentTime mỗi giây
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  //Hàm định dạng thời gian "Last Rotating"
  const formatLastChangeTime = (time) => {
    return time ? moment(time).fromNow() : 'Not available';
  };

  // Kiểm tra trạng thái loading và error trước khi render nội dung
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      <div className='bg-white border-b p-1 border-gray-200 p-3 text-sm font-semibold mb-5 flex justify-between'>
                                 <span> Proxy US Unlimited Bandwidth</span>
                                 <button   onClick={handleSortProxies} type="button" class="gap-2 px-3 py-1 text-exs font-medium text-center inline-flex items-center text-white bg-yellow-500 rounded-lg hover:bg-yellow-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                 <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.54163 12.0417H9.20829" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M3.54163 8.5H7.79163" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M3.54163 4.95837H6.37496" stroke="white" stroke-width="2" stroke-linecap="round"/>
<path d="M13.4584 4.25L15.5834 6.375M13.4584 4.25L11.3334 6.375M13.4584 4.25L13.4584 12.75" stroke="white" stroke-width="2"/>
</svg>

Sort Proxy
</button>
              </div>

    <div className="grid grid-cols-2 gap-5">
      {displayData.map((fastproxy) => {
        const filteredOptions = countryOptions.filter((option) => option !== fastproxy.proxy_country);

        return (
          <div key={fastproxy.id} className={` bg-white p-4 rounded-lg mb-1 ${clickedPorts.includes(fastproxy.id) ? "border border-red-500 drop-shadow-lg" : ""
            }`}>

            <label className={`text-sm font-semibold flex gap-2 ${
            clickedPorts.includes(fastproxy.id) ? "text-red-500" : "text-gray-600"
        }`}>
            {fastproxy.proxy_connect}
        </label>
            {showError && (
              <div className="text-red-500 text-xs mt-2">
                {errorMessages[fastproxy.id] && errorMessages[fastproxy.id]}
              </div>
            )}
            <div className="relative mt-2 text-gray-500 flex items-center">
              <div className="w-full left-3 my-auto h-6 flex items-center border-r pr-2">
                <select
                  className={`w-full text-sm  text-center border border-gray-200  rounded-lg h-full ${selectColors[fastproxy.proxy_port] === "red" ? "bg-red-500" :
                    selectColors[fastproxy.proxy_port] === "green" ? "bg-green-500" : "border-slate-400"
                    }`}
                  value={currentSelections[fastproxy.id] || fastproxy.proxy_country}
                  onChange={(e) => handleSelectChange(e, fastproxy.id, fastproxy.proxy_portkey, fastproxy.proxy_port)}
                >
                  <option value={fastproxy.proxy_country}>{fastproxy.proxy_country}</option>
                  {filteredOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <button
                className={`w-40 h-7 ml-2 px-2 text-white rounded-lg shadow-sm text-xs ${buttonColors[fastproxy.id] === "red" ? "bg-red-500" :
                  buttonColors[fastproxy.id] === "green" ? "bg-green-500" : "bg-slate-400"
                  } hover:bg-blue-600`}
                onClick={() => handleRotateIP(fastproxy.id, fastproxy.proxy_port, fastproxy.proxy_portkey)}
              >
                Xoay IP
              </button>
            </div>
            <div className="text-gray-500 text-xs mt-2">
              Last Rotating: {formatLastChangeTime(lastChangeTimes[fastproxy.id] || fastproxy.proxy_lastchangeip)}
            </div>
          </div>
        );
      })}
    </div>
    </div>
  );
}

export default FastProxyControl;