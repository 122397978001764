import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import axios from '../../../services/axios';


const ITEMS_PER_PAGE = 10;

const TopOfferTableData = () => {
    const topOfferTableData = useSelector(state => state.table.topOfferTableData);
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [newOffer, setNewOffer] = useState({
        offer_imagelink: '',
        offer_tag: ''
    });
    const openAddModal = () => {
        setNewOffer({
            offer_imagelink: '',
            offer_tag: ''
        });
        setAddModalOpen(true);
    };
    const saveNewOffer = () => {
        axios.post('/api/offer-images/', newOffer)
            .then((response) => {
                setAddModalOpen(false); // Đóng modal
            })
            .catch((error) => {
                // Xử lý khi có lỗi
                console.error('Error adding new offer:', error);
            });
    };

    useEffect(() => {
        if (topOfferTableData.length > 0) {
            const startIndex = currentPage * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setPaginatedData(topOfferTableData.slice(startIndex, endIndex));
        }
    }, [currentPage, topOfferTableData]);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    return (
        <div className='bg-white rounded-sm max-w-full'>
            <div className='flex justify-between  border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
                <span>Top Offer</span>
                <button className="bg-blue-500 hover:bg-blue-700 text-exs text-white rounded px-2" onClick={openAddModal}>+ Add App</button>
                {isAddModalOpen && (
                    // ... cấu trúc modal tương tự như trong ví dụ
                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen">
                            {/* Modal Content */}
                            <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal title" role="dialog" aria-modal="true">
                                <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                                    <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                                    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                    <div className="fixed inset-0 flex items-center justify-center z-50">
                                        <div className="bg-white dark:bg-gray-700 rounded-lg shadow-lg p-4 md:max-w-md w-full">
                                            <div className="flex justify-end">

                                            </div>

                                            <form className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8">
                                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">Add NewEmployee Management</h3>
                                                <div>
                                                    <div>
                                                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"> Image App Link</label>
                                                        <input
                                                            type="text"
                                                            value={newOffer.offer_imagelink}
                                                            onChange={(e) => setNewOffer({ ...newOffer, offer_imagelink: e.target.value })}
                                                            className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                                        />
                                                    </div>


                                                    <div>
                                                        <label className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300">Offers Tag</label>
                                                        <input
                                                            type="text"
                                                            value={newOffer.offer_tag}
                                                            onChange={(e) => setNewOffer({ ...newOffer, offer_tag: e.target.value })}
                                                            className="mb-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                                            required
                                                        />
                                                    </div>
                                                    <button type="button" onClick={saveNewOffer} className="w-full mt-10 mb-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save Offer</button>
                                                    <button type="button" onClick={() => setAddModalOpen(false)} className="w-full bg-gray-300 text-gray-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="bg-white rounded-lg p-2 overflow-x-none">
                
                <div className="divide-y divide-gray-200">
                    {paginatedData.map((item, index) => (
                        <li key={index} className="w-full py-2 flex items-center justify-between">
                            {/* Offer details left side */}
                            <div className="w-3/5 flex items-center">
                                <div className="icon flex justify-center items-center">
                                    <img
                                        src={item.offer_image_link || "https://easyappicon.com/image/adaptive-icon.svg"}
                                        alt="Offer Image"
                                        className="w-8 h-8 sm:w-12 sm:h-12 rounded-md"
                                    />
                                </div>
                                <div className="ml-2">
                                    <p className="text-xs font-semibold text-gray-900 md:truncate overflow-ellipsis overflow-hidden w-30 md:w-auto">
                                        {item.history_offername}
                                    </p>
                                    <p className="text-xs text-gray-500">{item.offer_coins} Coins</p>
                                </div>
                            </div>
                            {/* Revenue details right side */}
                            <div className="w-2/5 flex flex-col items-end md:flex-row gap-1 md:items-center md:justify-end">
                                <div className="text-xs text-gray-900 rounded pl-2 pr-2 bg-emerald-300">{item.history_network}</div>
                                <div className="text-xs text-gray-900 rounded pl-2 pr-2 bg-blue-300">${item.total_coins}</div>
                                <div className="text-xs text-gray-900 rounded pl-2 pr-2 bg-orange-300">{item.lead}</div>
                                <div className="flex justify-center items-center gap-1 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{item.offer_geo} <img
                                            src={`https://flagsapi.com/${item.offer_geo.toUpperCase()}/shiny/16.png`}
                                            alt={`Flag of ${item.offer_geo}`}
                                        /></div>
                                
                            </div>
                        </li>
                    ))}
                </div>
                <ReactPaginate
                    pageCount={Math.ceil(topOfferTableData.length / ITEMS_PER_PAGE)}
                    onPageChange={handlePageChange}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={1}
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    );
};

export default TopOfferTableData;
