import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ApexCharts from 'react-apexcharts';
import { fetchDailyUserRankData, selectDailyUserRankData } from '../../../services/chartsSlice';

function DailyRankChart() {
    const dispatch = useDispatch();
    const dailyUserRankData = useSelector(selectDailyUserRankData);
    const loading = useSelector(state => state.charts.loading.dailyUserRank);
    const error = useSelector(state => state.charts.error.dailyUserRank);

    useEffect(() => {
        dispatch(fetchDailyUserRankData());
    }, [dispatch]);

    // Filter out users with total_coins equal to 0
    const filteredData = dailyUserRankData.filter(user => user.total_coins > 0);

    // Prepare chart options for a Treemap with 20 distinct colors
    const chartOptions = {
        chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
                show: false // Hide the toolbar
            }
        },

        colors: [
            "#5DA5DA", "#FAA43A", "#60BD68", "#F17CB0", "#B2912F",
            "#B276B2", "#DECF3F", "#F15854", "#4D4D4D", "#1F77B4",
            "#AEC7E8", "#FF7F0E", "#FFBB78", "#2CA02C", "#98DF8A",
            "#D62728", "#FF9896", "#9467BD", "#C5B0D5", "#8C564B"
        ],
        plotOptions: {
            treemap: {
                distributed: true, // This will distribute the colors for each item
                enableShades: false
            }
        },
        dataLabels: {
            enabled: true,
            style: {
                fontFamily: 'Poppins, sans-serif', // Set font family to Poppins
                fontSize: '12px',
                colors: ['#000000'] // Set labels to black
            },
            formatter: function (value, { dataPointIndex }) {
                const user = filteredData[dataPointIndex];
                // Display both username and total_coins
                return `${user.username} - $ ${user.total_coins}`;
            },
            useHTML: true // Use HTML to render the labels
        }
    };

    const chartSeries = [{
        data: filteredData.map((user, index) => ({
            x: user.username,
            y: user.total_coins,
            fillColor: chartOptions.colors[index % chartOptions.colors.length] // Assign colors to each data point
        }))
    }];

    return (
        <div className="daily-rank-chart">
            {loading && <p>Loading...</p>}
            {error && <p>Error loading the chart data: {error.message}</p>}
            {!loading && !error && (
                <ApexCharts
                    options={chartOptions}
                    series={chartSeries}
                    type="treemap"
                    height={280}
                />
            )}
        </div>
    );
};

export default DailyRankChart;
