import React, { useState } from 'react';
import IproxyControl from "./proxy/iproxy";
import FastProxyControl from "./proxy/fastproxy";
import Statistics from "./overview/statistics ";
function Proxy() {
    // Sử dụng state để theo dõi thứ tự của các component
    const [order, setOrder] = useState(['IproxyControl', 'FastProxyControl']);

    // Hàm để tráo đổi vị trí của IproxyControl và FastProxyControl
    const swapComponents = () => {
        setOrder(prevOrder => prevOrder[0] === 'IproxyControl' ? ['FastProxyControl', 'IproxyControl'] : ['IproxyControl', 'FastProxyControl']);
    };

    return (
        <div className="mt-16">
            <div className="charttrend">
                <div className='mt-5 '>
                    <Statistics />
                </div>
                <div className="flex items-center gap-5 pl-5 shadow-sm">
                    
               
                    
<button type="button"  onClick={swapComponents} class="px-2 py-1 gap-2 m-1 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
<svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 14 3-3m-3 3 3 3m-3-3h16v-3m2-7-3 3m3-3-3-3m3 3H3v3"/>
</svg>
Đổi Vị Trí Proxy
</button>


                </div>
                <div style={{ height: 'calc(100vh - 230px)' }}  className="overflow-auto proxycontrol mb-6  grid gap-y-5 gap-x-6 md:grid-cols-2 xl:grid-cols-2 p-1">
                    
                    {order.map(component => (
                        component === 'IproxyControl' ? (
                            <div key={component} className='rounded-sm max-w-full h-screen overflow-auto'>
                              
                                <IproxyControl />
                            </div>
                        ) : (
                            <div key={component} className='rounded-sm max-w-full h-screen overflow-auto'>
                               
                                <FastProxyControl />
                            </div>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Proxy;
