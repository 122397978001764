import React from 'react';
import { useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

// Function to normalize data
const normalizeData = (data) => {
  // Get all unique dates
  const allDates = [...new Set(data.flatMap(site => site.data.map(entry => entry.x)))];
  allDates.sort((a, b) => new Date(a) - new Date(b));

  // Normalize each site's data
  return data.map(site => {
    const normalizedData = allDates.map(date => {
      const entry = site.data.find(d => d.x === date);
      return entry ? entry.y : 0;
    });
    return {
      name: site.name,
      data: normalizedData,
    };
  });
};

const SitesTrendChart = () => {
  // Use selector from Redux to get data
  const rawData = useSelector(state => state.charts.sitesTrendChartData);

  // Normalize the raw data
  const chartData = normalizeData(rawData);

  const extractMiddlePart = (siteName) => {
    const parts = siteName.split('.');
    return parts.length === 3 ? parts[1] : siteName;
  };

  // Create series data for the chart
  const seriesData = chartData.map(site => ({
    name: extractMiddlePart(site.name),
    data: site.data,
  }));

  // Get all unique dates for x-axis categories
  const allDates = [...new Set(rawData.flatMap(site => site.data.map(entry => entry.x)))];
  allDates.sort((a, b) => new Date(a) - new Date(b));

  // Create x-axis config
  const xaxisConfig = {
    type: 'category',
    categories: allDates,
  };

  // Chart configuration
  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: xaxisConfig,
    yaxis: {
      title: {

      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " $";
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 5
    }
  };

  return (
    <div className='bg-white rounded-sm'>
      <div className='border-b p-1 border-gray-200 p-3 text-sm font-semibold'>
        Thống Kê Site Theo Từng Ngày
      </div>
      <ReactApexChart
        className="bg-white rounded-lg"
        options={chartOptions}
        series={seriesData}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default SitesTrendChart;
